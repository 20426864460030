<template>
  <div class="container" v-if="detail">
    <breadcrumb :current="{ title: detail.title }"></breadcrumb>
    <main-info :title="detail.inheritorName">
      <img slot="img" :src="detail.titleImg" alt="">
      <div slot="content">
        <!-- <p>项目编号:{{ detail.projectCode }}</p> -->
        <p>级别:<dict-tag :type="dict.level" :value="detail.level" /></p>
        <p>项目名称:{{ detail.title }}</p>
        <p>类别: <dict-tag :type="dict.category" :value="detail.category" /></p>
        <p>批次:<dict-tag :type="dict.batch" :value="detail.batch" /></p>
        <p>公示时间:{{ formatDate(detail.createTime, 'yyyy年MM月dd日') }}</p>
        <p>区域:{{ detail.area }}</p>
      </div>
    </main-info>
    <!-- 选项卡 -->
    <category isDetail @change="changeCategory" :categorys="categorys"></category>
    <!-- project -->

    <!-- <audio "></audio> -->

    <div v-show="categoryIndex === 0" style="text-align: center;">
      <audio controls ref="audio" v-if="detail.audioUrl" style="width: 500px;" class="aud" controlsList='nodownload'>
        <source :src="detail.audioUrl" />
      </audio>
      <txt-view class="txt" :txt="detail.txt"></txt-view>
    </div>
    <comment v-v-show="categoryIndex === 1" :id="$route.params.id" :contentType="$route.meta.contentType" />
    <recommend url="inheritor" :id="$route.params.id"  :contentType="$route.meta.contentType" />
  </div>
</template>

<script>
import { getDetail, getDict } from "@/api/enjoy/enjoy";
// 主信息插槽
import MainInfo from "@/components/MainInfo";
import DictTag from '@/components/DictTag'
import Recommend from '@/components/RelatedRecommend'
// import { AudioPlayer } from '@liripeng/vue-audio-player'
// import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
export default {

  name: 'peopleDetail',
  components: {
    MainInfo, DictTag, Recommend
  },
  watch: {
     $route(){
      this.getData()

     } 
    },
  data() {
    return {
      detail: null,
      dict: {},
      loading: true,
      categoryIndex: 0,//分类下标
      audioList: [
        'http://txh-cdn.96qbhy.com/20180817175211dtC1vE3z.mp3',
        'http://txh-cdn.96qbhy.com/20181106105737sOcozMqw.mp3'
      ],
      categorys: [{
        name: '非遗详情',
        newsType: 1
      }, {
        name: '评论',
        newsType: 2
      }],
    }
  },
  created() {
    this.getData()
    this.getDictList()
  },
  methods: {
    onBeforePlay(next) {
      console.log('这里可以做一些事情...')
      next() // 开始播放
    },

    changeCategory(index) {
      this.categoryIndex = index
      // this.getList()
    },
    getData() {
      this.loading = true
      getDetail('/ich/inheritor/get', { id: this.$route.params.id }).then(res => {
        this.loading = false
        this.detail = res.data
      })
    },
    getDictList() {
      getDict('ich_inheritor_batch').then(res => { this.dict.batch = res.data })
      getDict('ich_inheritor_category').then(res => { this.dict.category = res.data })
      getDict('ich_inheritor_level').then(res => { this.dict.level = res.data })
    },
  },

}
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}
</style>